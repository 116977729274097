import React from "react";
import CountUp from "react-countup";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
//config url
import configData from "../../config.js";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";

var bgimg1 = require("./../../images/counterBg.webp");
var bgimg2 = require("./../../images/background/bg-5.png");

const StatisticsMobile = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  //contact form
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [requirement, setRequirement] = useState("");

  const showModal = () => {
    setIsOpen(true);
  };

  const hideModal = () => {
    setIsOpen(false);
  };

  const enabled =
  requirement.length > 0  && name.length > 0 && email.length > 0 && mobile.length > 0;

  //contact form POST API
  async function saveQuery(event) {
    event.preventDefault();
    let data = { name, email, mobile, requirement };

    let result = await fetch(`${configData.ADMIN_URL}/api/contact`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": configData.X_API_KEY,
        "x-api-secret": configData.X_API_SECRET,
        Accept: "applicatiion/json",
      },

      body: JSON.stringify(data),
    });

    const item = await result.json();
    console.log(item);
    if (item.status === "success") {
      window.location.href = `/thanks.php`;
      setIsOpen(false);
    } else {
      toast.error("Please Try Again!", { autoClose: 3000 });
    }
  }

  return (
    <>
      <div
        className="desktop-hidden section-full overlay-wraper sx-bg-secondry   p-t30 p-b30 ml-auto"
        data-stellar-background-ratio="0.5"
        style={{ backgroundImage: "url(" + bgimg1 + ")" }}
      >
        <div className="overlay-main bg-black opacity-05" />
        <div className="container">
          <div className="section-content">
            <div className="counter-blocks">
              <div className="row">
                <div className="col-xl-3 col-4 m-b30 p-10 mr-0 pr-0">
                  <div
                    className="sx-count bg-white sx-icon-box-wraper bg-repeat p-a15"
                    style={{ backgroundImage: "url(" + bgimg2 + ")" }}
                  >
                    <h2 className="m-t0 mb-1 sx-text-primary text-center">
                      <span className="counter">
                        <CountUp end={12500} duration={5} />
                      </span>
                      <span className="fc-3e">+</span>
                    </h2>
                    <h3 className="m-b0 fs-30 fsm-14 text-center">
                      Projects <span className="red">Done</span>
                    </h3>
                  </div>
                </div>
                <div className="col-xl-3 col-4 m-b30 pm-10">
                  <div
                    className="sx-count  bg-white sx-icon-box-wraper bg-repeat p-a15"
                    style={{ backgroundImage: "url(" + bgimg2 + ")" }}
                  >
                    <h2 className="m-t0 mb-1 sx-text-primary text-center">
                      <span className="counter">
                        <CountUp end={2012} duration={5} />
                      </span>
                    </h2>
                    <h3 className="m-b0 fs-30 fsm-14 text-center">
                      Service <span className="red">Since</span>
                    </h3>
                  </div>
                </div>
                <div className="col-xl-3 col-4 m-b30 p-10 mr-0 pl-0">
                  <div
                    className="sx-count  bg-white sx-icon-box-wraper bg-repeat p-a15"
                    style={{ backgroundImage: "url(" + bgimg2 + ")" }}
                  >
                    <h2 className="m-t0 mb-1 sx-text-primary text-center">
                      <span className="counter">
                        <CountUp end={3000} duration={5} />
                      </span>
                      <span className="fc-3e">+</span>
                    </h2>
                    <h3 className="m-b0 fs-30 fsm-14 text-center">
                      Cities <span className="red">Covered</span>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <h2 className="text-white mt--15">
              Explore More, & Get Best Offers
            </h2>
            <button onClick={showModal} className="counter-btn mt-2">
              Enquire Now
            </button>

            <Modal show={isOpen} onHide={hideModal}>
              <Modal.Header>
                <div
                  className="modal-header-img"></div>
                <p className="formText text-center">Discuss with Expert Now</p>
                <button
                  onClick={hideModal}
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </Modal.Header>

              <Modal.Body>
                <form method="post" className="text-center">
                  <div class="result"></div>
                  <div class="form-group">
                    <input
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      type="text"
                      className="form-input"
                      placeholder="Enter Name"
                    />
                  </div>

                  <div class="form-group">
                    <input
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      required=""
                      type="email"
                      className="form-input"
                      placeholder="Enter Email"
                    />
                  </div>

                  <div class="form-group">
                    <input
                      onChange={(e) => {
                        setMobile(e.target.value);
                      }}
                      required=""
                      type="number"
                      className="form-input"
                      placeholder="Enter Phone number"
                    />
                  </div>

                  <div class=" form-group">
                    <textarea
                      onChange={(e) => {
                        setRequirement(e.target.value);
                      }}
                      required=""
                      className="form-textarea"
                      placeholder="Enter Plot Size & Requirements"   
                    ></textarea>
                  </div>

                  <button
                    disabled={!enabled}
                    onClick={saveQuery}
                    className="submitBtn"
                    type="submit"
                  >
                    Get Free Quote
                  </button>
                </form>
              </Modal.Body>
              <Modal.Footer></Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    </>
  );
};

export default StatisticsMobile;

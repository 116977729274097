import React from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
import configData from "./../../../config.js";



const ExploreButton = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [name, setName] = useState("");
  //const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [requirement, setRequirement] = useState("");

  const characters =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const length = 9;
  let randomStr = "";

  for (let i = 0; i < length; i++) {
    const randomNum = Math.floor(Math.random() * characters.length);
    randomStr += characters[randomNum];
  }
  var email =  `noemail_${randomStr}@nakshewala.com`;

  let history = useHistory();
  const showModal = () => {
    setIsOpen(true);
  };

  const hideModal = () => {
    setIsOpen(false);
  };
  const enabled =
    requirement.length > 0  && name.length > 0 && mobile.length > 0;

  //contact form POST API
  async function saveQuery(event) {
    event.preventDefault();
    let data = { name, email, mobile, requirement };

    let result = await fetch(`${configData.ADMIN_URL}/api/contact`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": configData.X_API_KEY,
        "x-api-secret": configData.X_API_SECRET,
        Accept: "applicatiion/json",
      },

      body: JSON.stringify(data),
    });

    const item = await result.json();
    console.log(item);
    if (item.status === "success") {
      history.push('/thanks.php');
      setIsOpen(false);
    } else {
      toast.error("Please Try Again!", { autoClose: 3000 });
    }
  }

  return (
    <>
      <div className="btn-style">
                 

                  
                    <button onClick={showModal} className="btn view_all float-right">
                    Explore More
                    </button>
                  

                  <Modal show={isOpen} onHide={hideModal}>
                    <Modal.Header>
                      <div
                        className="modal-header-img"></div>
                      <p className="formText text-center">Discuss with Expert Now</p>
                      <button
                        onClick={hideModal}
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </Modal.Header>

                    <Modal.Body>
                      <form method="post" className="text-center">
                        <div class="result"></div>
                        <div class="form-group">
                          <input
                            onChange={(e) => {
                              setName(e.target.value);
                            }}
                            type="text"
                            className="form-input"
                            placeholder="Enter Name"
                          />
                        </div>

                       

                        <div class="form-group">
                          <input
                            onChange={(e) => {
                              setMobile(e.target.value);
                            }}
                            required=""
                            type="number"
                            className="form-input"
                            placeholder="Enter Phone number"
                          />
                        </div>

                        <div class=" form-group">
                          <textarea
                            onChange={(e) => {
                              setRequirement(e.target.value);
                            }}
                            required=""
                            className="form-textarea"
                            placeholder="Enter Requirements"
                          ></textarea>
                        </div>

                        <button
                          disabled={!enabled}
                          onClick={saveQuery}
                          className="submitBtn"
                          type="submit"
                        >
                          Get Free Quote
                        </button>
                      </form>
                    </Modal.Body>
                    <Modal.Footer></Modal.Footer>
                  </Modal>
                </div>
              
      <ToastContainer />
    </>
  );
};

export default ExploreButton;

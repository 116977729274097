import React from "react";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import ReactHtmlParser from "html-react-parser";
import HeaderText from "./../Elements/HeaderText";
import {  toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
//config url
import configData from "./../../config.js";

var bgimg1 = require("./../../images/background/bg5.jpg");

const About5 = (props) => {

  const [isOpen, setIsOpen] = React.useState(false);
  
  const showModal = () => {
    setIsOpen(true);
  };

  const hideModal = () => {
    setIsOpen(false);
  };
  //contact form
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [requirement, setRequirement] = useState("");

  const enabled =
    requirement.length > 0 &&  name.length > 0 &&  email.length > 0 && mobile.length > 0;

  async function saveQuery(event) {
    event.preventDefault();
    let data = { name, email, mobile, requirement };

    let result = await fetch(`${configData.ADMIN_URL}/api/contact`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": configData.X_API_KEY,
        "x-api-secret": configData.X_API_SECRET,
        Accept: "applicatiion/json",
      },

      body: JSON.stringify(data),
    });

    const item = await result.json();
    console.log(item);
    if (item.status === "success") {
      window.location.href = `/thanks.php`;
      setIsOpen(false);
    } else {
      toast.error("Please Try Again!", { autoClose: 3000 });
    }
  }

  return (
    <>
      <div
        className={`${
          props.page_content ? "d-block" : "d-none"
        } section-full  p-t30 p-b30 bg-no-repeat bg-pink`}
      >
        <div className="container">
          <div className="section-content">
            <div className="row">
              <div className="col-xl-5 col-lg-5 col-md-12 d-none">
                <div
                  className="home-2-about bg-bottom-left bg-no-repeat bg-cover"
                  style={{ backgroundImage: "url(" + bgimg1.default + ")" }}
                ></div>
              </div>
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className=" text-center">
                  <p className="fs-16 m-t0 sx-tilte fsm-14  mt-3 mb-3 text-right-mobile">
                    <HeaderText
                      short_description={ReactHtmlParser(
                        props.page_content ? props.page_content : ""
                      )}
                    />

                    {/*<ReadMore>{props.page_content ? props.page_content : ""}</ReadMore>*/}
                  </p>

                  <div className="text-center mb-2">
                    <button onClick={showModal} className="site-button2">
                      Consult Now
                    </button>
                  </div>

                  <Modal show={isOpen} onHide={hideModal}>
                    <Modal.Header>
                      <div
                        className="modal-header-img"></div>
                      <p className="formText text-center">Discuss with Expert Now</p>
                      <button
                        onClick={hideModal}
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </Modal.Header>

                    <Modal.Body>
                      <form method="post" className="text-center">
                        <div class="result"></div>
                        <div class="form-group">
                          <input
                            onChange={(e) => {
                              setName(e.target.value);
                            }}
                            type="text"
                            className="form-input"
                            placeholder="Enter Name"
                          />
                        </div>

                        <div class="form-group">
                      <input
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        required=""
                        type="email"
                        className="form-input"
                        placeholder="Enter Email"
                      />
                      </div>

                        <div class="form-group">
                          <input
                            onChange={(e) => {
                              setMobile(e.target.value);
                            }}
                            required=""
                            type="number"
                            className="form-input"
                            placeholder="Enter Phone number"
                          />
                        </div>

                        <div class=" form-group">
                          <textarea
                            onChange={(e) => {
                              setRequirement(e.target.value);
                            }}
                            required=""
                            className="form-textarea"
                            placeholder="Enter Plot Size & Requirements"
                          ></textarea>
                        </div>

                        <button
                          disabled={!enabled}
                          onClick={saveQuery}
                          className="submitBtn"
                          type="submit"
                        >
                          Get Free Quote
                        </button>
                      </form>
                    </Modal.Body>
                    <Modal.Footer></Modal.Footer>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </>
  );
};

export default About5;

import React from "react";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
//config url
import configData from "./../../config.js";

const ContactForm = (props) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [requirement, setRequirement] = useState("");

  const enabled =
    requirement.length > 0 && name.length > 0 && email.length > 0 && mobile.length > 0;

  async function saveQuery(event) {
    event.preventDefault();
    let data = { name, email, mobile, requirement };

    let result = await fetch(`${configData.ADMIN_URL}/api/contact`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": configData.X_API_KEY,
        "x-api-secret": configData.X_API_SECRET,
        Accept: "applicatiion/json",
      },

      body: JSON.stringify(data),
    });

    const item = await result.json();
   
    if (item.status === "success") {
      window.location.href = `/thanks.php`;
    } else {
      var msg = JSON.stringify(item.errors.email[0]);
      toast.error(msg, { autoClose: 3000 });
    }
  }
  return (
    <>
      <div className="display-form">
        <div className="bg-white">
          <form className="bg-white shadow-css p-a20" action="#" method="post">
            {props.hTag ? <h5 className="post-query text-center fw-600">
              Get Expert <span className="red">Advise Now</span>{" "}
              <span className="red v-align-m">
                <i class="fa fa-long-arrow-right"></i>
              </span>
            </h5> : <h3 className="post-query text-center fw-600">
              Get Expert <span className="red">Advise Now</span>{" "}
              <span className="red v-align-m">
                <i class="fa fa-long-arrow-right"></i>
              </span>
            </h3>}
            
            <div className="form-group">
              <label class="mt-0"> Name</label>
              <input
                type="text"
                onChange={(e) => {
                  setName(e.target.value);
                }}
                className="bg-gray-light bdr-gray-light  form-control"
                placeholder="Enter Your Name"
              />
            </div>

            <div className="form-group">
              <label class="mt-0">Contact Number</label>
              <input
                type="text"
                onChange={(e) => {
                  setMobile(e.target.value);
                }}
                className="bg-gray-light bdr-gray-light  form-control"
                placeholder="Enter Contact Number"
              />
            </div>

            <div className="form-group">
              <label class="mt-0">Email Address</label>
              <input
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                type="email"
                className="bg-gray-light bdr-gray-light  form-control"
                placeholder="Enter Email Address"
              />
              </div>

            <div className="form-group">
              <label class="mt-0">Plot size & Requirements</label>
              <textarea
                onChange={(e) => {
                  setRequirement(e.target.value);
                }}
                className="bg-gray-light bdr-gray-light  form-control"
                placeholder=" Enter Plot size & Requirements"
              ></textarea>
            </div>

            <button
              onClick={saveQuery}
              disabled={!enabled}
              type="button"
              value="submit"
              className="counter-btn w-100 site-cart-btn site-button-secondry mt-3"
            >
              Submit
            </button>
          </form>
        </div>
      </div>

      <ToastContainer />
    </>
  );
};

export default ContactForm;

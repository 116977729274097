import React from "react";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
//config url
import configData from "./../../config.js";

const FreeQuote = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  const showModal = () => {
    setIsOpen(true);
  };

  const hideModal = () => {
    setIsOpen(false);
  };
  //contact form
  
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [requirement, setRequirement] = useState("");

  const enabled =
    requirement.length > 0 && name.length > 0 && email.length > 0 && mobile.length > 0;

  async function saveQuery(event) {
    event.preventDefault();
    let data = { name, email, mobile, requirement };

    let result = await fetch(`${configData.ADMIN_URL}/api/contact`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": configData.X_API_KEY,
        "x-api-secret": configData.X_API_SECRET,
        Accept: "applicatiion/json",
      },

      body: JSON.stringify(data),
    });

    const item = await result.json();
   
    if (item.status === "success") {
      window.location.href = `/thanks.php`;
      setIsOpen(false);
    } else {
      toast.error("Please Try Again!", { autoClose: 3000 });
    }
  }

  return (
    <>
      <li onClick={showModal} class="d-md-none">
        <NavLink to={"/#"}>
          <i class="fa fa-quote-left"></i> Get Free Quote
        </NavLink>
      </li>

      <Modal show={isOpen} onHide={hideModal}>
        <Modal.Header>
          <div
            className="modal-header-img"></div>
          <p className="formText text-center">Discuss with Expert Now</p>
          <button
            onClick={hideModal}
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </Modal.Header>

        <Modal.Body>
          <form method="post" className="text-center">
            <div class="result"></div>
            <div class="form-group">
              <input
                onChange={(e) => {
                  setName(e.target.value);
                }}
                type="text"
                className="form-input"
                placeholder="Enter Name"
              />
            </div>

            <div class="form-group">
              <input
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                required=""
                type="email"
                className="form-input"
                placeholder="Enter Email"
              />
            </div>

            <div class="form-group">
              <input
                onChange={(e) => {
                  setMobile(e.target.value);
                }}
                required=""
                type="number"
                className="form-input"
                placeholder="Enter Phone number"
              />
            </div>

            <div class=" form-group">
              <textarea
                onChange={(e) => {
                  setRequirement(e.target.value);
                }}
                required=""
                className="form-textarea"
                placeholder="Enter Requirements"
              ></textarea>
            </div>

            <button
              disabled={!enabled}
              onClick={saveQuery}
              className="submitBtn"
              type="submit"
            >
              Get Free Quote
            </button>
          </form>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      
    </>
  );
};

export default FreeQuote;
